import { MenuProps } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
type MenuItem = Required<MenuProps>['items'][number];

export function getMenuItem(
  key: React.Key,
  label: React.ReactNode,
  icon?: React.ReactNode,
  children?: MenuItem[] | null,
  disabled?: boolean,
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  } as MenuItem;
}

export const getExistProp = (data: any) => {
  const result = Object.keys(data).reduce((nextData, key) => {
    if (data[key]) {
      return {
        ...nextData,
        [key]: data[key],
      };
    }

    return nextData;
  }, {});

  return result;
};

export function useSearchDebounce(delay = 350): [string, Dispatch<SetStateAction<string>>] {
  const [search, setSearch] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery];
}
export function formatPrice(number: number): string {
  return number.toLocaleString('vi-VN');
}