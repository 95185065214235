import { BorderOuterOutlined, DatabaseOutlined, SettingOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { UserCircle2, Blocks, List, ScreenShare } from 'lucide-react';
import { getMenuItem } from 'utils';

export const getOpenKeys = (pathname: string) => {
  if (pathname.startsWith('/web-builders')) {
    return ['Websites'];
  } else if (pathname.startsWith('/inventories')) {
    return ['Assets'];
  }
  return [];
};

export const menus: MenuProps['items'] = [
  // getMenuItem('Dashboard', 'Tổng quan', <UserCircle2 size={16} />),
  getMenuItem('Websites', 'Websites', <ScreenShare size={16} />, [
    getMenuItem('/web-builders', 'Quản lý websites', <List size={16} />),
    getMenuItem('/web-builders-2', 'Quản lý websites 2', <List size={16} />),
    getMenuItem('/web-builders/create', 'Siêu thị websites', <Blocks size={16} />),
    // getMenuItem('Add-Website-Outside', 'Thêm website ngoài', <Bolt size={16} />),
    // getMenuItem('Install-Plugin', 'Cài plugin mới', <Wrench size={16} />),
  ]),
  // getMenuItem('Orders', 'Quản lý đơn hàng', <UserCircle2 size={16} />),
  // getMenuItem('Finance', 'Quản lý tài chính', <UserCircle2 size={16} />),
  // getMenuItem('Account', 'Quản lý tài khoản', <UserCircle2 size={16} />, [
  //   getMenuItem('Client-Account', 'Tài khoản khách hàng', <UserCircle2 size={16} />),
  //   getMenuItem('Internal-Account', 'Tài khoản nội bộ', <UserCircle2 size={16} />),
  // ]),
  getMenuItem('/category-list', 'Danh mục', <List size={16} />),
  getMenuItem('/inventories', 'Kho tài nguyên', <DatabaseOutlined size={16} />),
  getMenuItem('Posts', 'Bài viết', <UserCircle2 size={16} />),
  getMenuItem('Settings', 'Cài đặt', <SettingOutlined size={16} />, [
    getMenuItem('/plans', 'Cài đặt gói gia hạn', <BorderOuterOutlined size={16} />),
  ]),
];

export const menusBreadcrumbs: { [key: string]: { title: string; href?: string }[] } = {
  '/web-builders': [{ title: 'Websites' }, { title: 'Danh Sách', href: '/web-builders' }],
  '/web-builders-2': [{ title: 'Websites' }, { title: 'Danh Sách', href: '/web-builders-2' }],
  '/web-builders/create': [{ title: 'Websites' }, { title: 'Siêu Thị Websites', href: '/web-builders/create' }],
  '/category-list': [{ title: 'Danh Mục', href: '/category-list' }],
  '/inventories': [{ title: 'Kho Tài Nguyên' }, { title: 'Kho Web Mẫu', href: '/inventories' }],
  '/plans': [{ title: 'Cài Đặt' }, { title: 'Cài Đặt Gói Gia Hạn', href: '/plans' }],
};
