import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resetAll, RootState } from 'store';
import { Loading, QueryParams } from 'types';
import { planService } from 'services';
import Plan from 'models/Plan';
interface PlanState {
  plans: Plan[];
  plansLoading: Loading;
  plansCount: number;
}

const name = 'plan';
const initialState: PlanState = {
  plans: [],
  plansLoading: 'idle',
  plansCount: 0,
};

export const fetchPlans = createAsyncThunk(`${name}/list-of-plans`, async (query: QueryParams, { rejectWithValue }) => {
  try {
    const response = await planService.getPlans(query);
    response.rows = response.rows.map((plan) => {
      const websiteIds = (plan.websitePlans || []).map((websitePlan) => websitePlan.websiteId); 
      return { ...plan, websiteIds };
    });

    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const PlanSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // the resetAll extra reducer is mandatory
    builder.addCase(resetAll, () => initialState);
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.plansLoading = 'pending';
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.plansLoading = 'idle';
        state.plans = action.payload.rows;
        state.plansCount = action.payload.count;
      })
      .addCase(fetchPlans.rejected, (state) => {
        state.plansLoading = 'idle';
      });
  },
});
export const selectPlans = (state: RootState) => {
  return state.plans.plans;
};
export const selectPlansLoading = (state: RootState) => {
  return state.plans.plansLoading;
};

export const selectPlansTotal = (state: RootState) => {
  return state.plans.plansCount;
};
export default PlanSlice.reducer;
