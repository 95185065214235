import WebsiteBuilder from 'models/WebsiteBuilder';
import request from 'requesters/index';
import { PaginationData, QueryParams } from 'types';

export type CreateWebsiteBuilderSubmitData = {
  websiteId: string;
  websitePassword: string;
  isTrial: boolean;
  websiteData: {
    domainName: string;
    package: string;
    adminEmail: string;
    phpSelection: string;
    ssl: number;
    dkimCheck: number;
    openBasedir: number;
  };
};

const websiteBuilderService = {
  getWebsiteBuilders: async (params?: QueryParams) => {
    return await request.get<PaginationData<WebsiteBuilder>>('/web-builder', {
      params,
    });
  },
  getWebsiteBuilder: async (websiteBuilderId: string, params?: QueryParams) => {
    return await request.get<WebsiteBuilder>(`/web-builder/${websiteBuilderId}`, {
      params,
    });
  },
  createWebsiteBuilder: async (data: CreateWebsiteBuilderSubmitData) => {
    return request.post('/web-builder/create-new-website', data);
  },
  verifyExistDomain: async (domainName: string) => {
    return request.post<Boolean>('/web-builder/verify-exist-domain', { domainName });
  }
};

export default websiteBuilderService;
