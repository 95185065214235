import { Image } from 'antd';

type ImagePreviewProps = {
  src: string;
  alt?: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
};

const ImagePreview = ({ src, alt = 'Image', width = 80, height = 80, style }: ImagePreviewProps) => {
  return (
    <Image.PreviewGroup>
      <Image
        src={src}
        alt={alt}
        width={width}
        height={height}
        placeholder={true}
        style={{
          objectFit: 'cover', 
          ...style, 
        }}
      />
    </Image.PreviewGroup>
  );
};

export default ImagePreview;
