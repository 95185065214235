import React from 'react';
import { ExclamationCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';

interface InfoBoxProps {
  message: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ message }) => {
  return (
    <div className="info-box">
      <ExclamationCircleFilled className="info-box__icon" />
      {message}
    </div>
  );
};

export default InfoBox;
