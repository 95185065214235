import { configureStore, ThunkAction, Action, createAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import loadingReducer from './slices/loadingSlice';
import websiteReducer from './slices/website.slice';
import categoryReducer from './slices/category.slices'; 
import websiteBuilderReducer from './slices/website-builder';
import planReducer from './slices/plan.slices';

export const resetAll = createAction('RESET_ALL');
export const store = configureStore({
  reducer: {
    loadingScheme: loadingReducer,
    websites: websiteReducer,
    category: categoryReducer,
    websiteBuilders: websiteBuilderReducer,
    plans: planReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
