import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { resetAll, RootState } from 'store';
import { Loading, QueryParams } from 'types';
import websiteBuilderService from 'services/website-builder.service';
import WebsiteBuilder from 'models/WebsiteBuilder';
interface WebsiteBuilderState {
  websiteBuilders: WebsiteBuilder[];
  websiteBuildersLoading: Loading;
  websiteBuildersCount: number;
  websiteBuilder: WebsiteBuilder | null;
  websiteBuilderLoading: Loading;
}

const name = 'website-builder';
const initialState: WebsiteBuilderState = {
  websiteBuilders: [],
  websiteBuildersLoading: 'idle',
  websiteBuildersCount: 0,
  websiteBuilder: null,
  websiteBuilderLoading: 'idle',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setState = (state: any, field: string, value: any) => {
  state[field] = value;
};

export const fetchWebsiteBuilders = createAsyncThunk(
  `${name}/list-of-website-builders`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await websiteBuilderService.getWebsiteBuilders(query);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const findWebsiteBuilder = createAsyncThunk(
  `${name}/get-one-website-builder`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const { websiteBuilderId, ...params } = query;
      if (!websiteBuilderId) return;
      const response = await websiteBuilderService.getWebsiteBuilder(websiteBuilderId, params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const websiteBuilderSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // the resetAll extra reducer is mandatory
    builder.addCase(resetAll, () => initialState);
    builder
      .addCase(fetchWebsiteBuilders.pending, (state) => {
        setState(state, 'websiteBuildersLoading', 'pending');
        setState(state, 'websiteBuilderLoading', 'pending');
      })
      .addCase(fetchWebsiteBuilders.fulfilled, (state, action) => {
        setState(state, 'websiteBuildersLoading', 'idle');
        setState(state, 'websiteBuilders', action.payload.rows);
        setState(state, 'websiteBuildersCount', action.payload.count);
      })
      .addCase(findWebsiteBuilder.fulfilled, (state, action) => {
        setState(state, 'websiteBuilderLoading', 'idle');
        setState(state, 'websiteBuilder', action.payload);
      })
      .addCase(fetchWebsiteBuilders.rejected, (state) => {
        setState(state, 'websiteBuildersLoading', 'idle');
        setState(state, 'websiteBuilderLoading', 'idle');
      });
  },
});
export const selectWebsiteBuilders = (state: RootState) => {
  return {
    rows: state.websiteBuilders.websiteBuilders,
    count : state.websiteBuilders.websiteBuildersCount
  };
};
export const selectWebsiteBuildersLoading = (state: RootState) => {
  return state.websiteBuilders.websiteBuildersLoading;
};

export const selectWebsiteBuildersTotal = (state: RootState) => {
  return state.websiteBuilders.websiteBuildersCount;
};
export const selectWebsiteBuilder = (state: RootState) => {
  return state.websiteBuilders.websiteBuilder;
};
export const selectWebsiteBuilderLoading = (state: RootState) => {
  return state.websiteBuilders.websiteBuilderLoading;
};

export default websiteBuilderSlice.reducer;
