import { Plan } from 'models';
import request from 'requesters/index';
import { DurationUnit, PaginationData, QueryParams } from 'types';

export type SubmitPlanType = {
  id?: string;
  name: string;
  price: number;
  duration: number;
  durationUnit: DurationUnit;
  isActive?: boolean;

  websiteIds?: string[];
}

const planService = {
  createOrUpdate: async (plans?: SubmitPlanType[]) => {
    return await request.post<Plan>('/plans', plans);
  },
  getPlans: async (params?: QueryParams) => {
    return await request.get<PaginationData<Plan>>(`/plans`, { params });
  },
  deletePlan: async (planId: string) => {
    return await request.delete<string>(`/plans/${planId}`);
  },
};

export default planService;
