import { useContext, useEffect, createContext, ReactNode, useState } from 'react';
import { landingPage, routes } from 'routes';
import { AppRouteType } from 'types';
import { matchPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { resetAll, useAppDispatch } from 'store';
import { getAccessToken, removeToken, setToken } from 'services/token.service';
import { User } from 'models';
import useMergeState from 'hooks/useMergeState';
import { Input, Modal, Spin } from 'antd';
import { authService } from 'services';

interface AuthContextType {
  isFirstLoading: boolean;
  loading: boolean;
  accessToken: null | string | undefined;
  profile: null | User;
  isAuth: boolean;
  onSignInWithPassword: (credentials: { email: string; password: string }) => Promise<any>;
  onSignUpWithPassword: (data: User) => Promise<any>;
  onSignInWithGoogle: (token: string) => Promise<any>;
  onSignOut: () => void;
  getProfile: () => void;
}

const AuthContext = createContext<AuthContextType>({
  isFirstLoading: true,
  loading: false,
  accessToken: null,
  profile: null,
  isAuth: false,
  onSignInWithPassword: () => Promise.resolve(),
  onSignUpWithPassword: () => Promise.resolve(),
  onSignInWithGoogle: () => Promise.resolve(),
  onSignOut: () => {},
  getProfile: () => {},
});

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const accessToken = getAccessToken();

  // get the current path with query params
  const [userData, setUserData] = useMergeState({
    isFirstLoading: true,
    loading: false,
    accessToken: accessToken,
    isAuth: false,
    profile: null,
  });

  const currentPage = routes?.find((_: AppRouteType) => (matchPath(_.path, location.pathname) ? true : false));
  useEffect(() => {
    if (!userData?.accessToken) {
      setUserData({ isFirstLoading: false, loading: false });
    }

    if (!currentPage) {
      navigate({ pathname: landingPage?.path }, { replace: true });
    }
    if (!userData?.accessToken && currentPage?.auth) {
      setUserData({ isFirstLoading: false, loading: false });
      navigate({ pathname: '/sign-in' }, { replace: true }); // If not authenticated, force log in
    }
    if (userData?.accessToken) {
      // check permission
      if (currentPage?.roles) {
        navigate('/404', { replace: true });
      } else if (!currentPage?.auth && currentPage?.isPublic) {
        // if already signed in, auto redirect to homepage if accesss to non-auth page
        navigate({ pathname: landingPage?.path }, { replace: true });
      }
      setUserData({ isFirstLoading: false, loading: false });
    }
    if (userData?.accessToken && !userData.profile) {
      getProfile();
    }
  }, [userData?.accessToken, currentPage]);

  const getProfile = async () => {
    try {
      setUserData({ loading: true });
      const user = await authService.userProfile();

      setUserData({
        isFirstLoading: false,
        loading: false,
        profile: user,
        isAuth: true,
      });

      if (currentPage?.name === 'Login') {
        navigate('/', { replace: true });
      }
    } catch (error: any) {
      onSignOut();
    }
  };

  const onSignInWithPassword = async (credentials: { email: string; password: string }) => {
    try {
      setUserData({ loading: true });
      const { token } = await authService.signInWithPassword(credentials);
      setSearchParams(searchParams);
      setToken(token);
      setUserData({ accessToken: token });
      setUserData({ isFirstLoading: false, loading: false });
    } catch (error: any) {
      setUserData({ isFirstLoading: false, loading: false });
      throw error;
    }
  };

  const onSignUpWithPassword = async (data: any) => {
    try {
      setUserData({ loading: true });
      await authService.signUpWithPassword(data).then((registerUserOutput: any) => {
        if (registerUserOutput) {
          const accessToken = registerUserOutput?.token;
          setToken(accessToken);
          setUserData({ accessToken: accessToken });
          setSearchParams(searchParams);
          if(registerUserOutput?.user){
            navigate({ pathname: landingPage?.path }, { replace: true });
          }
        }
      });
      
      setUserData({ isFirstLoading: false, loading: false });
    } catch (error: any) {
      console.log(error);
      setUserData({ isFirstLoading: false, loading: false });
      throw error;
    }
  };

  const onSignOut = async () => {
    if (userData?.profile) {
      try {
        await authService.signOut();
      } catch (error) {}
    }
    setUserData({
      isFirstLoading: false,
      loading: false,
      accessToken: null,
      idToken: null,
      isAuth: false,
      profile: null,
    });
    dispatch(resetAll());
    removeToken();
    navigate({ pathname: '/sign-in' }, { replace: true }); // If not authenticated, force log in
  };

  const onSignInWithGoogle = async (token: string) => {
    try {
      setUserData({ loading: true });
      const { isExist, token: accessToken } = await authService.loginWithGoogle({ token });
      if (isExist && accessToken) {
        setSearchParams(searchParams);
        setToken(accessToken);
        setUserData({ accessToken });
      }
    } catch (error: any) {
      setUserData({ isFirstLoading: false, loading: false });
      throw error;
    }
  };

  const modalConfirm = (type: string, title: string, message: string) => {
    const config = {
      title: title,
      content: message,
      centered: true,
    };
    switch (type) {
      case 'success':
        Modal.success(config);
        break;
      case 'error':
        Modal.error(config);
        break;
    }
  };
  return (
    <AuthContext.Provider
      value={{
        isFirstLoading: userData.isFirstLoading,
        loading: userData.loading,
        accessToken: userData.accessToken,
        profile: userData.profile,
        isAuth: userData.isAuth,
        onSignInWithPassword,
        onSignUpWithPassword,
        onSignInWithGoogle,
        onSignOut,
        getProfile,
      }}
    >
      {userData?.isFirstLoading || currentPage?.roles ? <Spin/> : children}
    </AuthContext.Provider>
  );
}

const useAuthContext = () => useContext(AuthContext);

export default useAuthContext;
