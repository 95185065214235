import { ReactNode } from 'react';
import { Layout as AntdLayout } from 'antd';
import SidebarLayout from './SidebarLayout';
import { ThemeProvider, styled } from 'styled-components';
import { useTheme } from '../../store/theme-context';
import { themecolor, darkthemecolors } from 'config/config';
import HeaderLayout from './Header';
import FooterLayout from './Footer';
const { Content } = AntdLayout;

const StyleLayout = styled(AntdLayout)`
  margin-left: ${themecolor.components.Menu.verticalSidebarWidth}px;
  position: relative;
  padding: calc(${themecolor.token.controlHeight}px * 2) 24px 0;

  .ant-breadcrumb {
    ol {
      justify-content: end;
    }
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

const Layout = ({ children }: { children: ReactNode }) => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme === 'dark' ? darkthemecolors : themecolor}>
      <SidebarLayout theme={theme} />
      <AntdLayout>
        <HeaderLayout />
        <StyleLayout id='antLayoutContent' className='layout'>
          <Content>{children}</Content>
        </StyleLayout>
        <FooterLayout />
      </AntdLayout>
    </ThemeProvider>
  );
};

export default Layout;
